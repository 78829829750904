import React, { useState } from "react"
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring';
import { useMeasure, useMedia } from 'react-use';
import HamburgerButton from './hamburgerButton';

const LogoButtonWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 60rem) {
        width: 100%;
    }
`;

const MenuContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 4rem;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);

    @media (max-width: 60rem) {
        flex-direction: column;
    }
`;

const NavigationWrapper = styled(animated.div)`
    height: auto;
    overflow: hidden;
    width: 100%;

    @media (max-width: 60rem) {
        height: 0;
        
    }
`;

const Navigation = styled(animated.nav)`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;

    @media (max-width: 60rem) {
        justify-content: flex-end;
    }
`;

const List = styled(animated.ul)`
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0 1rem;
    overflow: hidden;

    @media (max-width: 60rem) {
        flex-direction: column;
        padding: 1rem 0;
    }
`;

const ListItem = styled.li`
    float: left;

    a {
        display: block;
        color: #fff;
        text-align: right;
        padding: 0.5rem 1rem;
        text-decoration: none;
        font-weight: bold;
        font-size: 1.3rem;
        margin: 0 0.5rem;
        text-transform: uppercase;
        transition: all .2s ease-in-out;

        @media (hover: hover) {
            :hover {
                transform: scale(1.1);
            }
        }
    }

    a:hover {
        color: #fff;
    }

    .active {
        background-color: #f58220;
        color: #fff;

        :hover {
            color: #fff;
        }
    }
`;

const Spacer = styled.div`
    flex: 1;
`;

const Header = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [ref, { height }] = useMeasure();

    const spring = useSpring({
        config: { friction: 10, clamp: !openMenu },
        height: openMenu ? `${height}px` : '0px'
    });

    const isMobile = useMedia('(max-width: 60rem)');

    return (
        <MenuContainer>
            <LogoButtonWrapper>
                <Spacer />
                {isMobile &&
                    <HamburgerButton open={openMenu} onClick={() => setOpenMenu(!openMenu)} />
                }
            </LogoButtonWrapper>

            <NavigationWrapper style={isMobile ? spring : {}}>
                <Navigation ref={ref}>
                    <List>
                        <ListItem>
                            <a href="#home" onClick={() => setOpenMenu(false)}>Home</a>
                        </ListItem>
                        <ListItem>
                            <a href="#about-us" onClick={() => setOpenMenu(false)}>About Us</a>
                        </ListItem>
                        <ListItem>
                            <a href="#services" onClick={() => setOpenMenu(false)}>Services</a>
                        </ListItem>
                        <ListItem>
                            <a href="#gallery" onClick={() => setOpenMenu(false)}>Gallery</a>
                        </ListItem>
                        <ListItem>
                            <a href="#contact-us" onClick={() => setOpenMenu(false)}>Contact Us</a>
                        </ListItem>
                    </List>
                </Navigation>
            </NavigationWrapper>
        </MenuContainer>
    );
}

export default Header;
