import React from "react"
import { Global, css } from '@emotion/react'
import SEO from "../components/seo";
import HeroSection from "../components/sections/heroSection";
import Footer from "../components/footer";
import AboutSection from "../components/sections/aboutSection";
import ServicesSection from "../components/sections/servicesSection";
import GallerySection from "../components/sections/gallerySection";
import ContactSection from "../components/sections/contactSection";
import OrganogramSection from "../components/sections/organogramSection";

const globalCss = css`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

const IndexPage = () => {
  return (
    <>
      <SEO title="Industrial Control & Automation" />
      <main>
        <Global styles={globalCss} />
        <HeroSection />
        <AboutSection />
        <ServicesSection />
        <GallerySection />
        <ContactSection />
        <Footer />
      </main>
    </>
  )
}

export default IndexPage
