import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Section = styled.section`
    
`;

const Background = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem;
    background-color: #f2f2f2;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Heading = styled.h2`
    font-size: 3rem;
    margin: 2rem 0;
`;

const Gallery = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: center;
`;

const GalleryItem = styled.div`
  margin: 1rem;
`;

const GallerySection = () => {
  const data = useStaticQuery(
    graphql`
    query ImagesForGallery {
      images: allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(width: 250, height: 250) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `
  )

  const images = data.images.edges;

  console.log("images", images);

    return (
        <Section id="gallery">
            <Background>
                <Heading>Gallery</Heading>
                <Gallery>
                  {images.map(img => <GalleryItem><Img fixed={img.node.childImageSharp.fixed} /></GalleryItem>)}
                </Gallery>
            </Background>
        </Section>
    )
}

export default GallerySection;