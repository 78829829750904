import React from "react";
import styled from "@emotion/styled";

const Section = styled.section`
    
`;

const Background = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem;
    background-color: #f2f2f2;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Heading = styled.h2`
    font-size: 3rem;
    margin: 2rem 0;
`;

const TileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media (max-width: 60rem) {
        flex-direction: column;
    }
`;

const Tile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;;
    width: 100%;
    margin: 0 1rem;
    background-color: #fff;
    border-radius: 25px;

    @media (max-width: 60rem) {
        margin: 1rem 0;
    }
`;

const TileCopy = styled.div`
    flex: 1;
    padding: 1rem;
`;

const TileHeading = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #2a166f;
    width: 100%;
    height: 5rem;
    border-radius: 25px 25px 0 0;
    padding: 0 8px;

    img {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.5rem;
    }

    h3 {
        margin: 0;
        color: #fff;
        line-height: 1.5rem;
        text-align: center;
        text-transform: uppercase;
    }
`;

const locationImage = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+IDxzdmcgaWQ9IkxheWVyXzEiIGRhdGEtbmFtZT0iTGF5ZXIgMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjx0aXRsZT5NYXBzLCBHUFM8L3RpdGxlPjxwYXRoIGQ9Ik0xNS45MiwxNS4yMywxOC4zNSwxMWE3LjI3LDcuMjcsMCwwLDAsMC03LjM1LDcuMzUsNy4zNSwwLDAsMC0xMi43MiwwLDcuMjcsNy4yNywwLDAsMCwwLDcuMzVsMi40Myw0LjIxQzQsMTUuNzEsMCwxNywwLDE5LjUsMCwyMi41OSw2LjIyLDI0LDEyLDI0czEyLTEuNDEsMTItNC41QzI0LDE3LDIwLDE1LjcxLDE1LjkyLDE1LjIzWk03LjM3LDQuNjdBNS4zNCw1LjM0LDAsMSwxLDE2LjYyLDEwTDEyLDE4LDcuMzgsMTBBNS4zMSw1LjMxLDAsMCwxLDcuMzcsNC42N1pNMTIsMjJDNS40LDIyLDIsMjAuMjUsMiwxOS41YzAtLjUzLDIuMTUtMS45NSw3LjE4LTIuMzhsMS4zNSwyLjMzYTEuNywxLjcsMCwwLDAsMi45NCwwbDEuMzUtMi4zM0MxOS44NSwxNy41NSwyMiwxOSwyMiwxOS41LDIyLDIwLjI1LDE4LjYsMjIsMTIsMjJaIiBmaWxsPSIjZmZmIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI3IiByPSIyIiBmaWxsPSIjZmZmIi8+PC9zdmc+';

const ContactSection = () => {
    return (
        <Section id="contact-us">
            <Background>
                <Heading>Contact Us</Heading>
                <TileContainer>
                    <Tile>
                    <img url={locationImage} alt="" />
                        <TileHeading>
                            {/* <img src={locationImage} alt="" /> */}
                            <h3>Head Office</h3>
                        </TileHeading>
                        <TileCopy>
                            <p>56 Ivy Park, 3 Ivy Road, Pinetown, 3610</p>
                            <p>P.O. Box 15655, Westmead, 3610</p>
                            <p>Tel: <a href="tel:+27317090811">+27 31 709 0811</a></p>
                            <p>Fax: <a href="tel:+27317080812">+27 31 708 0812</a></p>
                            <p>Email: <a href="mailto:ica@mweb.co.za">ica@mweb.co.za</a></p>
                        </TileCopy>
                    </Tile>
                    <Tile>
                        <TileHeading>
                            {/* <img src={locationImage} alt="" /> */}
                            <h3>Richards Bay Office</h3>
                        </TileHeading>
                        <TileCopy>
                            <p>Tel: <a href="tel:+27357898322">+27 35 789 8322</a></p>
                            <p>Cell: <a href="tel:+27828790693">+27 82 879 0693</a></p>
                            <p>Fax: <a href="tel:+27865457764">+27 86 545 7764</a></p>
                            <p>Email: <a href="mailto:terrence@inauto.co.za">terrence@inauto.co.za</a></p>
                        </TileCopy>
                    </Tile>
                    <Tile>
                        <TileHeading>
                            {/* <img src={locationImage} alt="" /> */}
                            <h3>PCI Northwest & Limpopo (t/a)</h3>
                        </TileHeading>
                        <TileCopy>
                            <p>214 Beyers Naude Drive, Rustenburg, 0299</p>
                            <p>Cell: <a href="tel:+27798255135">+27 79 825 5135</a></p>
                            <p>Tel: <a href="tel:+27145233611">+27 14 523 3611</a></p>
                            <p>Email: <a href="mailto:thato@inauto.co.za">thato@inauto.co.za</a></p>
                            <br/>
                            <p>Plot 17, De Kroon, Brits, 0250</p>
                            <p>Cell: <a href="tel:+27818063369">+27 81 806 3369</a></p>
                            <p>Email: <a href="mailto:danie@inauto.co.za">danie@inauto.co.za</a></p>
                        </TileCopy>
                    </Tile>
                    <Tile>
                        <TileHeading>
                            {/* <img src={locationImage} alt="" /> */}
                            <h3>African Office</h3>
                        </TileHeading>
                        <TileCopy>
                            <p>Unitech Automation Limited - Zambia</p>
                            <p>ICA Tanzania</p>
                            <p>ICA Mozambique</p>
                            <p>Malawi</p>
                            <p>Swaziland</p>
                        </TileCopy>
                    </Tile>
                </TileContainer>
            </Background>
        </Section>
    )
}

export default ContactSection;