import React from "react";
import styled from "@emotion/styled";

const Section = styled.section`
    
`;

const Background = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem;
    background-color: #e9e9e9;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Heading = styled.h2`
    font-size: 3rem;
    margin: 2rem 0;
`;

const TileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
`;

const Tile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;;
    flex: 0 0 23%;
    margin: 1rem 0;
    background-color: #fff;
    border-radius: 25px;
    min-height: 300px;

    @media (max-width: 1280px) {
        flex: 0 0 30%;
    }

    @media (max-width: 800px) {
        flex: 0 0 45%;
    }

    @media (max-width: 600px) {
        flex: 0 0 100%;
    }
`;

const TileCopy = styled.div`
    flex: 1;
    padding-right: 1rem;
`;

const TileHeading = styled.h3`
    margin: 0;
    background-color: #2a166f;
    color: #fff;
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    text-align: center;
    text-transform: uppercase;
    border-radius: 25px 25px 0 0;
`;

const ServicesSection = () => {
    return (
        <Section id="services">
            <Background>
                <Heading>Services</Heading>


                <TileContainer>
                    <Tile>
                        <TileHeading>
                            Instrumentation
                        </TileHeading>
                        <TileCopy>
                            <ul>
                                <li>Engineering</li>
                                <li>Design</li>
                                <li>Installation</li>
                                <li>Calibration</li>
                                <li>Commissioning</li>
                                <li>Testing</li>
                                <li>Drawings</li>
                                <li>Ultrasonic Inspections</li>
                                <li>Thermal Inspections</li>
                            </ul>
                        </TileCopy>
                    </Tile>
                    <Tile>
                        <TileHeading>
                            Electrical
                        </TileHeading>
                        <TileCopy>
                            <ul>
                                <li>Engineering</li>
                                <li>Design</li>
                                <li>Installation</li>
                                <li>Commissioning</li>
                                <li>Testing</li>
                                <li>Drawings</li>
                                <li>LV & MV</li>
                                <li>Earthing</li>
                                <li>Earth Testing</li>
                            </ul>
                        </TileCopy>
                    </Tile>

                    <Tile>
                        <TileHeading>
                            Agencies
                        </TileHeading>
                        <TileCopy>
                            <ul>
                                <li>Yokogawa Process control Instrumentation</li>
                                <li>Elen Enclosures</li>
                                <li>Weidmuller Products</li>
                                <li>Rhomberg Instruments</li>
                                <li>Grafoplast Wiring Systems</li>
                                <li>Weighing Systems</li>
                                <li>Kinetrol actuators</li>
                                <li>Nivelco</li>
                            </ul>
                        </TileCopy>
                    </Tile>

                    <Tile>
                        <TileHeading>
                            Panel Building
                        </TileHeading>
                        <TileCopy>
                            <ul>
                                <li>Control System for various DCS & PLC vendors</li>
                                <li>Exporting to the Middle East & Africa</li>
                            </ul>
                        </TileCopy>
                    </Tile>
                </TileContainer>
            </Background>
        </Section>
    )
}

export default ServicesSection;