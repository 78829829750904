import React from "react";
import styled from "@emotion/styled";

const Section = styled.section`
    
`;

const Background = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem;
    background-color: #f2f2f2;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Heading = styled.h2`
    font-size: 3rem;
    margin: 2rem 0;
`;

const Copy = styled.div`

`;

const AboutSection = () => {
    return (
        <Section id="about-us">
            <Background>
                <Heading>About Us</Heading>
                <Copy>
                    <p>
                        Industrial Control and Automation is a fully integrated BBBEE Company, focusing on all facets of Instrumentation and Electrical projects. Mark Calvert and Christopher Ngongoma are the directors of our company. Our core staff compliment consist of Instrument Fitters, Electricians, Instrument Mechanicians all with an average of 18 years' experience in their fields.
                    </p>

                    <p>
                        They are backed by our affiliated Electrical and Instrumentation Engineer's and our Master Installation Electrician with 20 years' experience. We hire in additional labour for Turnkey projects and where possible, utilize local skilled and unskilled labour.
                    </p>

                    <p>
                        Our admin department is backed by 20 years` experience and a comprehensive database. Together with the capabilities of a full service design & drawing office.
                    </p>
                </Copy>
            </Background>
        </Section>
    )
}

export default AboutSection;