import React from "react"
import styled from "@emotion/styled";

const FooterRoot = styled.footer`
    background-color: #2a166f;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    color: #fff;
    text-align: center;
`;

const Footer = () => {
    return (
        <FooterRoot>
            © 2021 Industrial Control & Automation. All Rights Reserved.
        </FooterRoot>
    )
}

export default Footer;
