import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import Header from "../header";
import BackgroundImage from "gatsby-background-image";
import LogoImg from "../../images/logo.png";
import Marquee from 'react-double-marquee';

const Section = styled.section`
    
`;

const Background = styled(BackgroundImage)`
    min-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #999394;
`;

const Contact = styled.div`
  min-height: 4rem;
  background-color: #2a166f;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  h1 {
    font-size: 1.2rem;
    margin: 1rem;
  }

  @media (max-width: 60rem) {
    flex-direction: column;
    padding: 1rem 0;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 60rem) {
    flex-direction: column;
  }
`;

const ContactItem = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  color: #fff;
  text-decoration: none;

  span {
    font-size: 1.2rem;
  }

  img {
      height: 1.5rem;
      margin-right: 0.5rem;
  }

  @media (max-width: 60rem) {
    margin: 0.5rem 0;
  }
`;

const TitleContainer = styled.div`
  background-color: rgba(75,175,230, 0.8);
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  h1 {
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    margin: 0;
    font-size: 2rem;
  }

  h2 {
    color: #2d1971;
    text-transform: uppercase;
    text-align: left;
    margin: 0.5rem 0 0 0;
    font-size: 1.5rem;
  }

  img {
    max-height: 5rem;
    margin-right: 1rem;
  }

  @media (max-width: 60rem) {
    flex-direction: column;
    
    img {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }

    h1, h2 {
      text-align: center;
    }
  }
`;

const Test = styled.div`

`;

const MarqueeWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  color: #afdcf4;

  @media (max-width: 60rem) {
    flex: none;
    width: 100%;
    margin: 0.5rem 0;
  }

  a {
    color: #afdcf4;
  }
`;

const HeroSection = () => {
  const data = useStaticQuery(
    graphql`
        query {
          background: file(relativePath: { eq: "refinery-light.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
  )

  const backgroundData = data.background.childImageSharp.fluid;

  return (
    <Section id="home">
      <Background fluid={backgroundData}>
        <Header />
        <TitleContainer>
          <img src={LogoImg} alt="" />
          <Test>
            <h1>Industrial Control & Automation</h1>
            <h2>PCI Northwest & Limpopo (t/a)</h2>
            <h2>Specialist Instrumentation & Electrical Contractors</h2>
          </Test>
        </TitleContainer>
      </Background>
      <Contact>
        <ContactWrapper>
          <ContactItem href="mailto:ica@mweb.co.za">
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICAgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLW1haWwiPjxwYXRoIGQ9Ik00IDRoMTZjMS4xIDAgMiAuOSAyIDJ2MTJjMCAxLjEtLjkgMi0yIDJINGMtMS4xIDAtMi0uOS0yLTJWNmMwLTEuMS45LTIgMi0yeiI+PC9wYXRoPjxwb2x5bGluZSBwb2ludHM9IjIyLDYgMTIsMTMgMiw2Ij48L3BvbHlsaW5lPjwvc3ZnPg==" alt="Mail" />
            <span>ica@mweb.co.za</span>
          </ContactItem>

          <ContactItem href="tel:+27317090811">
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICAgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLXBob25lLWNhbGwiPjxwYXRoIGQ9Ik0xNS4wNSA1QTUgNSAwIDAgMSAxOSA4Ljk1TTE1LjA1IDFBOSA5IDAgMCAxIDIzIDguOTRtLTEgNy45OHYzYTIgMiAwIDAgMS0yLjE4IDIgMTkuNzkgMTkuNzkgMCAwIDEtOC42My0zLjA3IDE5LjUgMTkuNSAwIDAgMS02LTYgMTkuNzkgMTkuNzkgMCAwIDEtMy4wNy04LjY3QTIgMiAwIDAgMSA0LjExIDJoM2EyIDIgMCAwIDEgMiAxLjcyIDEyLjg0IDEyLjg0IDAgMCAwIC43IDIuODEgMiAyIDAgMCAxLS40NSAyLjExTDguMDkgOS45MWExNiAxNiAwIDAgMCA2IDZsMS4yNy0xLjI3YTIgMiAwIDAgMSAyLjExLS40NSAxMi44NCAxMi44NCAwIDAgMCAyLjgxLjdBMiAyIDAgMCAxIDIyIDE2LjkyeiI+PC9wYXRoPjwvc3ZnPg==" alt="Phone" />
            <span>+27 31 709 0811</span>
          </ContactItem>
        </ContactWrapper>


        <MarqueeWrapper>
          <Marquee direction="left" childMargin={0} delay={0}>
            <a href="https://www.yokogawa.com/za/solutions/products-platforms/field-instruments/flow-meters/" target="_blank" rel="noreferrer">Flow</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a href="https://www.yokogawa.com/za/solutions/products-platforms/field-instruments/level-meters/" target="_blank" rel="noreferrer">Level</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a href="https://www.yokogawa.com/za/solutions/products-platforms/field-instruments/pressure-transmitters/" target="_blank" rel="noreferrer">Pressure</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a href="https://www.yokogawa.com/za/solutions/products-platforms/field-instruments/temperature-transmitters/" target="_blank" rel="noreferrer">Temperature</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a href="https://www.yokogawa.com/za/solutions/products-platforms/control-system/controllers-indicators/" target="_blank" rel="noreferrer">Controllers</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a href="https://www.yokogawa.com/za/solutions/products-platforms/data-acquisition/" target="_blank" rel="noreferrer">Recorders</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a href="https://www.yokogawa.com/za/solutions/products-platforms/process-analyzers/" target="_blank" rel="noreferrer">Analytical</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a href="https://www.yokogawa.com/za/solutions/products-platforms/control-system/distributed-control-systems-dcs/" target="_blank" rel="noreferrer">D.C.S.</a>&nbsp;&nbsp;•&nbsp;&nbsp;Instrumentation Installation&nbsp;&nbsp;•&nbsp;&nbsp;Electrical Installations&nbsp;&nbsp;•&nbsp;&nbsp;Project Management&nbsp;&nbsp;•&nbsp;&nbsp;Turnkey&nbsp;&nbsp;•&nbsp;&nbsp;Drawing Services&nbsp;&nbsp;•&nbsp;&nbsp;Thermography&nbsp;&nbsp;•&nbsp;&nbsp;Ultrasonic Inspections&nbsp;&nbsp;•&nbsp;&nbsp;
          </Marquee>
        </MarqueeWrapper>
      </Contact>
    </Section>
  )
}

export default HeroSection;
