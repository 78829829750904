import React from "react";
import styled from "@emotion/styled";
import OrganogramImg from "../../images/organogram.png";
const Section = styled.section`
    
`;

const Background = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem;
    background-color: #f2f2f2;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Heading = styled.h2`
    font-size: 3rem;
    margin: 2rem 0;
`;

const OrganogramContainer = styled.div`
    width: 100%;

    img {
        width: 100%;
    }
`;

const OrganogramSection = () => {
    return (
        <Section id="organogram">
            <Background>
                <Heading>Organogram</Heading>
                <OrganogramContainer>
                    <a href="/documents/ICA-Organogram.pdf">
                        <img src={OrganogramImg} alt="Organogram" />
                    </a>
                </OrganogramContainer>
            </Background>
        </Section>
    )
}

export default OrganogramSection;